<template>
    <div>
        <div class="container-fluid">
            <div class="row pb-2">
                <div class="col-10 float-left">
                    <h3 class="d-inline">#Dr指示書</h3>
                    <h6 class="d-inline pl-2" v-if="person">
                        {{ person.relationships.facility.attributes.name }} / {{ person.attributes.name }}様
                    </h6>
                </div>
                <div class="col-2 float-right">
                    <button type="button"
                            class="btn btn-outline-dark float-right"
                            @click="openCreateModal">＋
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <table class="table table-bordered bg-white">
                        <thead class="bg-dark">
                        <th>作成者</th>
                        <th>作成日</th>
                        <th>最終更新者</th>
                        <th>最終更新日</th>
                        <th>表示</th>
                        <th>編集</th>
                        </thead>
                        <tbody>
                        <tr v-for="report in reports">
                            <td>{{ report.attributes.creator_name }}</td>
                            <td>{{ report.attributes.regist_date | moment('YYYY年MM月DD日') }}</td>
                            <td>{{ report.attributes.editor_name }}</td>
                            <td>{{ report.attributes.updated_at | moment('YYYY年MM月DD日') }}</td>
                            <td><a href="javascript:void(0)" @click="next(report)">表示</a></td>
                            <td><a href="javascript:void(0)" @click="openEditModal(report)">編集</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <create-doctor-direction-modal ref="createModal" @instruction-report-has-created="createReport">
        </create-doctor-direction-modal>
        <edit-doctor-direction-modal ref="editModal" @instruction-report-has-updated="updateReport">
        </edit-doctor-direction-modal>
    </div>
</template>

<script>
import CreateDoctorDirectionModal from "@/components/DoctorDirection/CreateDoctorDirectionModal/CreateDoctorDirectionModal";
import EditDoctorDirectionModal from "@/components/DoctorDirection/EditDoctorDirectionModal/EditDoctorDirectionModal";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "DoctorDirection",
    components: {EditDoctorDirectionModal, CreateDoctorDirectionModal},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isSelectTarget: false,
            person: null,
            reports: {},
        }
    },
    created: function () {
        this.loadPerson();
        this.loadDoctorDirections();
    },
    methods: {
        openCreateModal: function () {
            this.$refs['createModal'].openModal(this.person);
        },
        openEditModal: function (report) {
            this.$refs['editModal'].openModal(this.person, report);
        },
        next: function (report) {
            this.$router.push({name: 'DisplayDoctorDirection', params: {id: report.id}});
        },
        loadPerson: function () {
            axios.get(this.routes.person(this.$route.params['id']))
                .then(response => {
                    this.person = response.data.data;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        loadDoctorDirections: function () {
            axios.get(this.routes.InstructionReports, {
                params: {
                    person_id: this.$route.params['id'],
                }
            })
                .then(response => {
                    this.reports = response.data.data;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        createReport: function (report) {
            this.reports.unshift(report);
        },
        updateReport: function ($event) {
            const index = this.reports.findIndex(function (report) {
                return report.id === $event.id;
            });
            if (index !== -1) {
                this.reports.splice(index, 1, $event);
            }
        }
    }
}
</script>

<style scoped>

</style>
