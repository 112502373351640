<template>
    <CModal
        title="Dr指示書 作成"
        color="dark"
        size="lg"
        :show.sync="isOpened">
        <div class="container-fluid p-3">
            <div class="row">
                <div class="col-xl-12">
                    <form>
                        <div class="form">
                            <v-input label="利用者氏名"
                                     id="name"
                                     type="text"
                                     disabled
                                     :value="name">
                            </v-input>
                            <v-input label="生年月日"
                                     id="birthday"
                                     type="text"
                                     disabled
                                     :value="birthday">
                            </v-input>
                            <v-input label="年齢"
                                     id="age"
                                     type="text"
                                     disabled
                                     unit="歳"
                                     :value="getAge(birthday).toString()">
                            </v-input>
                            <v-input label="要介護・障害認定の状況"
                                     id="status"
                                     type="text"
                                     disabled
                                     :value="remark">
                            </v-input>
                            <v-input label="住所"
                                     id="address"
                                     type="text"
                                     disabled
                                     :value="address">
                            </v-input>
                            <v-input label="指示書日付"
                                     id="create-regist-date"
                                     type="date"
                                     placeholder="入力してください"
                                     :errors="hasErrors('regist_date')"
                                     v-model="form.regist_date">
                            </v-input>
                            <v-input label="訪問看護指示期間(開始日)"
                                     id="term"
                                     type="date"
                                     placeholder="入力してください"
                                     :errors="hasErrors('start_date')"
                                     v-model="form.start_date">
                            </v-input>
                            <v-input label="主たる傷病名"
                                     id="diseases-name"
                                     type="text"
                                     placeholder="入力してください"
                                     :errors="hasErrors('illness')"
                                     v-model="form.illness">
                            </v-input>
                            <v-input label="症状・治療状況"
                                     id="diseases-name"
                                     type="text"
                                     placeholder="入力してください"
                                     :errors="hasErrors('status')"
                                     v-model="form.status">
                            </v-input>
                            <v-input label="投与中の薬剤の容量・用法"
                                     id="drug-name"
                                     type="text"
                                     placeholder="入力してください"
                                     :errors="hasErrors('usage')"
                                     v-model="form.usage">
                            </v-input>
                            <v-input  label="日常生活自立度"
                                      id="edit-autonomy-degree"
                                      type="text"
                                      placeholder="入力してください"
                                      :errors="hasErrors('independence')"
                                      v-model="form.independence">
                            </v-input>
                            <v-text-area id="nursing-reason"
                                         label="看護理由"
                                         placeholder="入力してください"
                                         :errors="hasErrors('reason')"
                                         v-model="form.reason">
                            </v-text-area>
                            <v-text-area id="nursing-reason"
                                         label="Dr指示内容1"
                                         placeholder="入力してください"
                                         :errors="hasErrors('instruction1')"
                                         v-model="form.instruction1">
                            </v-text-area>
                            <v-text-area id="nursing-reason"
                                         label="Dr指示内容2"
                                         placeholder="入力してください"
                                         :errors="hasErrors('instruction2')"
                                         v-model="form.instruction2">
                            </v-text-area>
                            <v-text-area id="nursing-reason"
                                         label="Dr指示内容3"
                                         placeholder="入力してください"
                                         :errors="hasErrors('instruction3')"
                                         v-model="form.instruction3">
                            </v-text-area>
                            <v-text-area id="nursing-reason"
                                         label="Dr指示内容4"
                                         placeholder="入力してください"
                                         :errors="hasErrors('instruction4')"
                                         v-model="form.instruction4">
                            </v-text-area>
                            <v-radio-group label="Dr指示内容5選択内容" :errors="hasErrors('instruction5_choice')">
                                <v-radio label="週1回"
                                         name="radio"
                                         value="0"
                                         id="create-instruction5-choice-0"
                                         :checked="form.instruction5_choice === '0'"
                                         :errors="hasErrors('instruction5_choice')"
                                         @change="changeInstruction5Choice">
                                </v-radio>
                                <v-radio label="週2回"
                                         name="radio"
                                         value="1"
                                         id="create-instruction5-choice-1"
                                         :checked="form.instruction5_choice === '1'"
                                         :errors="hasErrors('instruction5_choice')"
                                         @change="changeInstruction5Choice">
                                </v-radio>
                                <v-radio label="週3回以上"
                                         name="radio"
                                         value="2"
                                         id="create-instruction5-choice-2"
                                         :checked="form.instruction5_choice === '2'"
                                         :errors="hasErrors('instruction5_choice')"
                                         @change="changeInstruction5Choice">
                                </v-radio>
                                <v-radio label="その他"
                                         name="radio"
                                         value="3"
                                         id="create-instruction5-choice-3"
                                         :checked="form.instruction5_choice === '3'"
                                         :errors="hasErrors('instruction5_choice')"
                                         @change="changeInstruction5Choice">
                                </v-radio>
                            </v-radio-group>
                            <v-input id="nursing-reason"
                                         label="Dr指示内容5"
                                         placeholder="入力してください"
                                         :errors="hasErrors('instruction5')"
                                         v-model="form.instruction5"
                                         :disabled="form.instruction5_choice !== '3'">
                            </v-input>
                            <v-input label="特記すべき留意事項"
                                     id="attention-matter"
                                     type="text"
                                     placeholder="入力してください"
                                     :errors="hasErrors('remarks')"
                                     v-model="form.remarks">
                            </v-input>
                            <v-input label="医療機関名"
                                     id="hospital-address"
                                     type="text"
                                     placeholder="入力してください"
                                     :errors="hasErrors('hospital_name')"
                                     v-model="form.hospital_name">
                            </v-input>
                            <v-input label="住所"
                                     id="hospital-address"
                                     type="text"
                                     placeholder="入力してください"
                                     :errors="hasErrors('hospital_address')"
                                     v-model="form.hospital_address">
                            </v-input>
                            <v-input label="電話"
                                     id="hospital-tel"
                                     type="text"
                                     placeholder="入力してください"
                                     :errors="hasErrors('hospital_tel')"
                                     v-model="form.hospital_tel">
                            </v-input>
                            <v-input label="FAX"
                                     id="hospital-fax"
                                     type="text"
                                     placeholder="入力してください"
                                     :errors="hasErrors('hospital_fax')"
                                     v-model="form.hospital_fax">
                            </v-input>
                            <v-input label="医師氏名"
                                     id="doctor-name"
                                     type="text"
                                     placeholder="入力してください"
                                     :errors="hasErrors('doctor_name')"
                                     v-model="form.doctor_name">
                            </v-input>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="createInstructionReport" :disabled="sending">作成</button>
        </template>
    </CModal>
</template>

<script>
import VTextArea from "@/components/Forms/VTextArea";
import VInput from "@/components/Forms/VInput";
import VSelect from "@/components/Forms/VSelect";
import VRadioGroup from "@/components/Forms/VRadioGroup";
import VRadio from "@/components/Forms/VRadio";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
import Helpers from "@/components/Systems/Helpers";

export default {
    name: "CreateDoctorDirectionModal",
    components: {VSelect, VTextArea, VInput, VRadioGroup, VRadio},
    mixins: [ApiRouter, ErrorHandler, Helpers],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            name: '',
            birthday: '',
            remark: '',
            address: '',
            form: {
                person_id: '',
                regist_date: '',
                start_date: '',
                illness: '',
                status: '',
                usage: '',
                independence: '',
                reason: '',
                instruction1: '',
                instruction2: '',
                instruction3: '',
                instruction4: '',
                instruction5_choice: '',
                instruction5: '',
                remarks: '',
                hospital_name: '',
                hospital_address: '',
                hospital_tel: '',
                hospital_fax: '',
                doctor_name: ''
            }
        }
    },
    methods: {
        openModal: function (person) {
            this.isOpened = true;
            this.initModal(person);
            this.clearErrors();
        },
        initModal: function (person) {
            this.form.person_id = person.id;
            this.name = person.attributes.name;
            this.birthday = person.attributes.birthday;
            this.remark = person.attributes.remark;
            this.address = person.attributes.address;
        },
        changeInstruction5Choice: function ($event) {
            this.form.instruction5_choice = $event;
            if (this.form.instruction5_choice !== '3') {
                this.form.instruction5 = '';
            }
        },
        createInstructionReport: function () {
            this.sending = true;
            this.showIndicator('作成中');

            axios.post(this.routes.InstructionReports, this.form)
                .then(response => {
                    this.$emit('instruction-report-has-created', response.data.data);
                    this.showSuccessPopup('登録しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        }
    }
}
</script>
